<template>
  <div class="page-card-demo  page-info-content">
    <el-form v-if="guarCmpList.length" ref="addForm" :inline="true" :model="formParams" :rules="formRules" label-width="140px" size="small">
      <detailTemplate :detail-config="detailConfig" :form-params="formParams" />
    </el-form>
    <div class="add_organazition_btn">
      <el-button size="small" @click="handleCancel">
        取消
      </el-button>
      <el-button type="primary" size="small" @click="handleSubmit">
        确定
      </el-button>
    </div>
  </div>
</template>
<script>
import { getGaranteeCompanyList } from '@/api/guaranteeCompany.js'
import { guarGreditFundDetail, addGuarCreditFund, updateOrderInfo } from '@/api/tradeFinancing.js'
import DetailTemplate from '../../components/detailTemplate.vue'
import { operateArrayObj } from '@/utils/index.js'
export default {
  components: { DetailTemplate },
  data() {
    return {
      formParams: {
        fundCmpName: '', // 授信资金方
        guarCmpName: '', // 担保方名称
        guarCmpId: '', // 担保方id
        guarCmpUnicode: '', // 担保方社会信用代码
        creditAmt: '', // 授信额度
        endDate: '', // 额度到期日
        custAmt: '', // 单户限额
        execRate: '', // 融资利率(%年化)
        execFee: '', // 融资手续费率(%年化)
        abledAmt: '', // 可用额度
        usedAmt: 0, // 已用额度
        frozenAmt: 0, // 冻结额度
        remarks: '' // 备注
      },
      formRules: {
        fundCmpName: [{ required: true, validator: this.fundCmpNameValidator, trigger: 'change' }], // 授信资金方
        guarCmpId: [{ required: true, trigger: 'change', message: '请选择担保方' }], // 担保方
        creditAmt: [{ required: true, validator: this.creditAmtValidator, trigger: 'change' }], // 授信额度
        endDate: [{ required: true, trigger: 'change', message: '请选择额度到期日' }], // 额度到期日
        custAmt: [{ required: true, validator: this.custAmtValidator, trigger: 'change' }], // 单户限额
        execRate: [{ required: true, validator: this.rateValidator, trigger: 'change' }], // 融资利率(%年化)
        execFee: [{ required: true, validator: this.rateValidator, trigger: 'change' }], // 融资手续费率(%年化)
        remarks: [{ max: 200, message: '最多200个字符', trigger: 'change' }] // 备注
      },
      guarCmpList: [],
      detailConfig: [
        {
          title: '基本信息',
          dataType: 'form',
          dataList: [
            {
              label: '授信资金方',
              type: 'input',
              isForm: true,
              value: 'fundCmpName',
              prop: 'fundCmpName'
            },
            {
              label: '担保方',
              isForm: true,
              type: 'select',
              value: 'guarCmpId',
              prop: 'guarCmpId',
              selectData: this.guarCmpList,
              inputChange: this.guarCmpChange
            },
            {
              label: '授信额度',
              isForm: true,
              type: 'money',
              value: 'creditAmt',
              prop: 'creditAmt',
              inputChange: this.creditAmtChange
            },
            {
              label: '额度到期日',
              isForm: true,
              type: 'date',
              value: 'endDate',
              prop: 'endDate',
              valueFormat: 'timestamp',
              dateOptions: this.endDateOptions
            },
            {
              label: '单户限额',
              isForm: true,
              type: 'money',
              value: 'custAmt',
              prop: 'custAmt'
            },
            {
              label: '融资利率(%年化)',
              isForm: true,
              type: 'rate',
              value: 'execRate',
              prop: 'execRate'
            },
            {
              label: '可用额度',
              type: 'money',
              value: 'abledAmt',
              prop: 'abledAmt',
              disabled: true
            },
            {
              label: '融资手续费率(%年化)',
              labelWidth: '200px',
              type: 'rate',
              value: 'execFee',
              prop: 'execFee'
            },

            {
              label: '已用额度',
              type: 'money',
              value: 'usedAmt',
              prop: 'usedAmt',
              disabled: true
            },
            {
              label: '冻结额度',
              type: 'money',
              value: 'frozenAmt',
              prop: 'frozenAmt',
              disabled: true
            },
            {
              label: '备注',
              type: 'textarea',
              value: 'remarks',
              prop: 'remarks',
              itemClassName: 'width100'
            }
          ]
          // formParams: this.formParams
        }
      ]
    }
  },
  created() {
    this.$route.query.id && this.init()
    this.getGaranteeCompanyList() // 获取担保方
  },
  methods: {
    init() {
      guarGreditFundDetail(this.$route.query.id, res => {
        if (res.code === 200) {
          this.formParams = res.data
        }
      })
    },
    getGaranteeCompanyList() {
      getGaranteeCompanyList(res => {
        this.guarCmpList = res.data
        const guarCmpList = operateArrayObj(this.guarCmpList, 'guaranteeCmpName', 'id')
        this.detailConfig[0].dataList[1].selectData = guarCmpList
      })
    },
    creditAmtChange(e, row, i) {
      row.abledAmt = e
      this.$forceUpdate()
    },
    // 额度到期日 当前日期之后
    endDateOptions(row, i) {
      return {
        disabledDate(time) {
          const thisTime = time.getTime()
          const startTmp = Date.now() - 8.64e7
          return thisTime < startTmp
        }
      }
    },
    // 担保方名称查询后选择回调
    guarCmpChange(e, row, i) {
      const t = this.guarCmpList.filter(item => item.id === e)[0]
      row.guarCmpUnicode = t.guaranteeCmpUnicode
      row.guarCmpName = t.guaranteeCmpName
      this.$forceUpdate()
    },
    handleCancel() {
      this.$router.go(-1)
    },
    // 提交
    handleSubmit() {
      const form = this.$refs['addForm']
      form.validate(valid => {
        if (valid) {
          const params = {
            ...this.formParams,
            creditState: '01'
          }
          this.submitAjax(params)
        } else {
          this.$message({
            type: 'error',
            message: '页面输入项有误，请检查'
          })
          return false
        }
      })
    },
    addGuarCreditFund(params) {
      addGuarCreditFund(params, res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '新增授信机构成功'
          })
        }
        this.$router.push('/productManage/creditOrganization')
      })
    },
    updateGuarCreditFund(params) {
      updateOrderInfo(params, res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '编辑授信机构成功'
          })
        }
        this.$router.push('/productManage/creditOrganization')
      })
    },
    submitAjax(params) {
      !this.$route.query.id && this.addGuarCreditFund(params)// 新增
      this.$route.query.id && this.updateGuarCreditFund(params)// 更新
    }
  }
}
</script>
<style lang="scss"  scoped>
  .add_organazition_btn {
    display: flex;
    justify-content: center;

  }
</style>
